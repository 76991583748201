export const PAYMENTS_BATCH_DELETE_REQUEST = 'PAYMENTS_BATCH_DELETE_REQUEST'
export const PAYMENTS_BATCH_DELETE_SUCCESS = 'PAYMENTS_BATCH_DELETE_SUCCESS'
export const PAYMENTS_BATCH_DELETE_FAILURE = 'PAYMENTS_BATCH_DELETE_FAILURE'

export const PAYMENTS_FETCH_MANY_REQUEST = 'PAYMENTS_FETCH_MANY_REQUEST'
export const PAYMENTS_FETCH_MANY_SUCCESS = 'PAYMENTS_FETCH_MANY_SUCCESS'
export const PAYMENTS_FETCH_MANY_FAILURE = 'PAYMENTS_FETCH_MANY_FAILURE'

export const PAYMENTS_FETCH_REQUEST = 'PAYMENTS_FETCH_REQUEST'
export const PAYMENTS_FETCH_SUCCESS = 'PAYMENTS_FETCH_SUCCESS'
export const PAYMENTS_FETCH_FAILURE = 'PAYMENTS_FETCH_FAILURE'

export const PAYMENTS_SAVE_REQUEST = 'PAYMENTS_SAVE_REQUEST'
export const PAYMENTS_SAVE_SUCCESS = 'PAYMENTS_SAVE_SUCCESS'
export const PAYMENTS_SAVE_FAILURE = 'PAYMENTS_SAVE_FAILURE'

export const EMPTY_PAYMENTS = 'EMPTY_PAYMENTS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
