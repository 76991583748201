<template>
	<router-view />
</template>

<script>
import paymentModule from "../../store/payments"
import customerModule from "../../store/customers"
import userModule from "../../store/users"
import lenderModule from "../../store/lenders"

export default {
	beforeCreate() {
		this.$store.registerModule("payments", paymentModule)
		this.$store.registerModule("customers", customerModule)
		this.$store.registerModule("users", userModule)
		this.$store.registerModule("lenders", lenderModule)
	},
	destroyed() {
		this.$store.unregisterModule("payments")
		this.$store.unregisterModule("customers")
		this.$store.unregisterModule("users")
		this.$store.unregisterModule("lenders")
	},
}
</script>
