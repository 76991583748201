<template>
	<b-container>
		<b-form @submit.prevent="submit">
			<b-row class="mb-3">
				<b-col>
					<h1 class="font-weight-bold text-primary">Creating Refund for Payment #{{ $route.params.id }}</h1>
				</b-col>
			</b-row>
			<b-row v-if="isLoading">
				<b-col>
					<p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
				</b-col>
			</b-row>
			<b-row v-if="!isLoading">
				<b-col>
					<b-form-group label="Staff Member" :invalid-feedback="validationInvalidFeedback(errors.errors, 'user_id')">
						<user-search :error-feed="errors.errors" error-key="user_id" type="user" @input="userUpdated" v-model="refund.user" />
					</b-form-group>

					<b-form-group label="Refund Amount" :invalid-feedback="validationInvalidFeedback(errors.errors, 'refund_amount')">
						<b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'refund_amount')" v-model="refund.refund_amount" />
					</b-form-group>

					<b-form-group label="Refund Method" :invalid-feedback="validationInvalidFeedback(errors.errors, 'method')">
						<b-form-select :error-feed="errors.errors" error-key="method" :state="validationState(errors.errors, 'method')" v-model="refund.method">
							<b-form-select-option :value="null">Please select a refund method</b-form-select-option>
							<b-form-select-option value="Cash">Cash</b-form-select-option>
							<b-form-select-option value="Debit">Debit</b-form-select-option>
							<b-form-select-option value="Finance">Finance</b-form-select-option>
							<b-form-select-option value="BACS">BACS</b-form-select-option>
							<b-form-select-option value="Cheque">Cheque</b-form-select-option>
						</b-form-select>
					</b-form-group>

					<b-form-group label="Reason" :invalid-feedback="validationInvalidFeedback(errors.errors, 'reason')">
						<b-form-textarea :disabled="isSaving" :state="validationState(errors.errors, 'reason')" v-model="refund.reason" />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col offset-md="8">
					<b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
				</b-col>
			</b-row>
		</b-form>
	</b-container>
</template>

<script>
	import validation from "../../mixins/validation"
	import currentUser from "../../mixins/currentUser"
	import { mapActions, mapGetters } from "vuex"
	import UserSearch from "../../components/UserSearch"
	import OrderSearch from "../../components/OrderSearch"

	export default {
		mixins: [ validation, currentUser ],
		components: { UserSearch, OrderSearch },
		created() {
			this.refund.user.id = this.currentUser.id
			this.refund.user_id = this.currentUser.id

			this.fetch(this.$route.params.id)
				.then(response => {
					this.payment = response.data
				})
		},
		data: () => ({
			payment: {
				id: null,
				order_id: null,
				order: {},
				user_id: null,
				user: {},
				amount: null,
				method: null,
				date: null,
				location: null,
				refunds: [],
			},

			refund: {
				user_id: null,
				user: { id: null, },
				refund_amount: null,
				method: null,
				reason: null,
			}
		}),
		computed: {
			...mapGetters("payments", [ "errors", "isLoading", "isSaving", "single" ])
		},
		methods: {
			...mapActions("payments", [ "fetch", "saveRefund" ]),

			submit() {
				this.saveRefund({
					payment_id: this.payment.id,
					refund: this.refund
				}).then(response => {
					if ([200, 201].includes(response.status)) this.$router.push({
							name: "dash.payments.view",
							params: {
								id: this.$route.params.id,
								notifications: [
									{
										variant: "success",
										title: `Refund #${response.data.id}`,
										text: `You have created a new refund.`,
										timeout: 5000
									}
								]
							}
						})
				})
			},

			userUpdated() {
				this.refund.user_id = this.refund.user.id
			},
		}
	}
</script>

<style>
</style>
