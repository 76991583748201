<template>
  <resource-search v-model="value" :error-feed="errorFeed" :error-key="errorKey"
                   :fetch-params="extraFetchParams" :show-delete="showDelete" :wait-for-input="waitForInput"
                   module="orders" @delete="() => this.$emit('delete')" @input="(id) => this.$emit('input', id)">
    <template #popover-list="{ result }">
      <b-row>
        <b-col cols="12">
          <div class="d-flex flex-row">
            <p class="font-weight-bold mb-0">#{{ result.id }}</p>
          </div>
          <div class="d-flex flex-row">
            <p class="font-weight-normal mb-0">{{ result.customer.name }}</p>
          </div>
          <div class="d-flex flex-column">
            <small class="text-muted font-weight-normal mb-0">{{ result.customer.address_line_1 }}</small>
            <small class="text-muted font-weight-normal mb-0">{{ result.customer.address_line_2 }}</small>
            <small class="text-muted font-weight-normal mb-0">{{ result.customer.county }}</small>
            <small class="text-muted font-weight-normal mb-0">{{ result.customer.postcode }}</small>
          </div>
        </b-col>
      </b-row>
    </template>

    <template #selected="{ selected }">
      <b-row>
        <b-col cols="12">
          <div class="d-flex flex-row">
            <p class="font-weight-bold mb-0">#{{ selected.id }}</p>
          </div>
          <div class="d-flex flex-row">
            <p class="font-weight-normal mb-0">{{ selected.customer.name }}</p>
          </div>
          <div class="d-flex flex-column">
            <small class="text-muted font-weight-normal mb-0">{{ selected.customer.address_line_1 }}</small>
            <small class="text-muted font-weight-normal mb-0">{{ selected.customer.address_line_2 }}</small>
            <small class="text-muted font-weight-normal mb-0">{{ selected.customer.county }}</small>
            <small class="text-muted font-weight-normal mb-0">{{ selected.customer.postcode }}</small>
          </div>
        </b-col>
      </b-row>
    </template>

    <template #selected-form-area>
      <slot name="form-area"/>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from "./ResourceSearch.vue"

export default {
  components: {ResourceSearch},
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    waitForInput: {
      type: Boolean,
      default: true,
    },
    errorFeed: Object,
    errorKey: String,
    type: String,
    value: Object,
  },
  computed: {
    extraFetchParams() {
      if (this.type) {
        return {type: this.type}
      }

      return {}
    },
  },
}
</script>

<style>
</style>
