<template>
  <b-container>
    <ResourceList :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" :search-query="$route.query.query" can-delete
                  resource="payment" soft-deletes title="Payments Due"
                  @paginate="onPaginate" @search="onSearch" @delete-many="onDeleteMany">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary">{{ item.customer.name }}</p>
            <p class="align-self-end mb-1 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <p class="mb-0">Order ID: {{ item.id }}</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button v-b-popover.hover.bottom="'View more information about this order.'" :to="{ name: 'dash.orders.view', params: { id: item.id } }" class="mr-1 mb-0"
                      size="sm"
                      variant="secondary">
              <b-icon icon="file-check"/>
              View
            </b-button>
            <b-button v-b-popover.hover.bottom="'Create a payment.'" :to="{ name: 'dash.payments.create', query: { order_id: item.id, user_id: item.user_id, total_price: item.total_price } }" class="mr-1 mb-0" size="sm"
                      variant="success">
              <b-icon icon="cash"/>
              Create Payment
            </b-button>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
  mixins: [currentUser],
  created() {
    this.fetchManyUnpaid(this.$route.query)
  },
  components: {
    ResourceList,
    FriendlyDate,
  },
  computed: {
    ...mapGetters("payments", ["data", "isDeleting", "isLoading", "pagination"]),
  },
  methods: {
    ...mapActions("payments", ["batchDelete", "fetchManyUnpaid"]),

    async onDeleteMany({ids, modalId}) {
      await this.batchDelete({
        ids,
      })
      this.$bvModal.hide(modalId)
      await this.fetchManyUnpaid(this.$route.query)
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page,
          },
        })
        await this.fetchManyUnpaid({
          ...this.$route.query,
          page,
        })
      }
    },
    async onSearch(query) {
      if (this.$route.query.query !== query) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            query,
          },
        })
        await this.fetchManyUnpaid({
          ...this.$route.query,
          query,
        })
      }
    },
  },
}
</script>
<style></style>
