<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">
            {{ $route.params.id ? "Update" : "Create" }} Payment
          </h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center">
            <b-spinner variant="secondary"></b-spinner>
          </p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col class="d-flex flex-row" cols="12" md="6">
              <b-button block class="align-self-end mb-3" variant="primary"
              >Total Inc Vat:
                {{ payment.total_price | currencyFormat }}
              </b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'order_id')
                "
                label="Order"
              >
                <order-search
                  v-model="payment.order"
                  :error-feed="errors.errors"
                  :error-key="'order_id'"
                  class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                  type="order"
                  @input="orderUpdated"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'user_id')
                "
                label="Staff Member"
              >
                <user-search
                  v-model="payment.user"
                  :error-feed="errors.errors"
                  class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                  error-key="user_id"
                  type="user"
                  @input="userUpdated"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="12" md="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'location')
                "
                label="Location"
              >
                <b-form-select
                  v-model="payment.location"
                  :error-feed="errors.errors"
                  :state="validationState(errors.errors, 'location')"
                  error-key="location"
                >
                  <b-form-select-option :value="null"
                  >Please select a payment location
                  </b-form-select-option
                  >
                  <b-form-select-option value="In Store"
                  >In Store
                  </b-form-select-option
                  >
                  <b-form-select-option value="Home">Home</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6" md="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'amount')
                "
                label="Amount"
              >
                <b-form-input
                  v-model="payment.amount"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'amount')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6" md="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'method')
                "
                label="Payment Method"
              >
                <b-form-select
                  v-model="payment.method"
                  :error-feed="errors.errors"
                  :state="validationState(errors.errors, 'method')"
                  error-key="method"
                  label="Payment Method"
                >
                  <b-form-select-option :value="null"
                  >Please select a payment method
                  </b-form-select-option
                  >
                  <b-form-select-option value="Cash">Cash</b-form-select-option>
                  <b-form-select-option value="Debit"
                  >Debit
                  </b-form-select-option
                  >
                  <b-form-select-option value="Finance"
                  >Finance
                  </b-form-select-option
                  >
                  <b-form-select-option value="BACS">BACS</b-form-select-option>
                  <b-form-select-option value="Cheque"
                  >Cheque
                  </b-form-select-option
                  >
                  <b-form-select-option value="Other"
                  >Other
                  </b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col v-if="payment.method == 'Other'" cols="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'other')
                "
                label="Other Payment Method"
              >
                <b-form-input
                  v-model="payment.other"
                  :disabled="isSaving"
                  :state="validationState(errors.errors, 'other')"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="12" md="12">
              <b-form-group
                :invalid-feedback="
                  validationInvalidFeedback(errors.errors, 'date')
                "
                label="Payment Date"
              >
                <b-form-datepicker
                  id="date"
                  v-model="payment.date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  :state="validationState(errors.errors, 'date')"
                  :value="payment.date"
                  close-button
                  reset-button
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button
                block
                class="align-self-end mb-3"
                type="submit"
                variant="secondary"
              >
                <b-spinner v-if="isSaving" small></b-spinner
                >
                <span v-if="!isSaving">Save</span></b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation";
import currentUser from "../../mixins/currentUser";
import {mapActions, mapGetters} from "vuex";
import UserSearch from "../../components/UserSearch";
import OrderSearch from "../../components/OrderSearch";

export default {
  mixins: [validation, currentUser],
  components: {UserSearch, OrderSearch},
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id);
    } else {
      if (this.$route.query.order_id) {
        this.payment.order_id = this.$route.query.order_id;
        this.payment.order.id = this.$route.query.order_id;
      }
      if (this.$route.query.total_price) {
        this.payment.total_price = this.$route.query.total_price;
      }
      this.payment.user.id = this.currentUser.id;
      this.payment.user_id = this.currentUser.id;
    }
  },
  data: () => ({
    payment: {
      order_id: null,
      order: {},
      user_id: null,
      user: {id: null},
      customer_id: null,
      customer: {},
      amount: null,
      method: null,
      date: new Date().toISOString().slice(0, 10),
      lender_id: null,
      lender: {},
      subsidy: null,
      interest: null,
      total_price: null,
      location: null
    }
  }),
  mounted() {
    this.payment.user = this.currentUser;
    this.payment.user_id = this.currentUser.id;
  },
  computed: {
    ...mapGetters("payments", ["errors", "isLoading", "isSaving", "single"])
  },
  methods: {
    ...mapActions("payments", ["fetch", "save"]),

    userUpdated() {
      this.payment.user_id = this.payment.user.id;
    },

    submit() {
      let data = {
        order_id: this.payment.order_id,
        user_id: this.payment.user_id,
        method: this.payment.method,
        date: this.payment.date,
        amount: this.payment.amount,
        total_price: this.payment.order.total_price,
        other: this.payment.other,
        location: this.payment.location
      };

      this.save(data).then(response => {
        if ([200, 201].includes(response.status))
          this.$router.push({
            name: "dash.payments.list",
            params: {
              notifications: [
                {
                  variant: "success",
                  title: `Payment #${response.data.id}`,
                  subtitle: `The payment has been applied against order ${response.data.order_id}`,
                  text: `You have ${
                    !this.$route.params.id
                      ? "created a new"
                      : "updated an existing"
                  } payment.`,
                  timeout: 5000
                }
              ]
            }
          });
      });
    },

    orderUpdated() {
      this.payment.order_id = this.payment.order.id;
      this.payment.total_price = this.payment.order.total_price;
    },
    lenderUpdated() {
      this.payment.lender_id = this.payment.lender.id;
    }
  },
  watch: {
    single() {
      this.payment = this.single;
      this.order = this.single.order;
    },
    currentUser() {
      this.payment.user = this.currentUser;
      this.payment.user_id = this.currentUser.id;
    }
  },
  filters: {
    currencyFormat: v =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP"
      }).format(v)
  }
};
</script>

<style></style>
