import {
    LENDERS_BATCH_DELETE_REQUEST,
    LENDERS_BATCH_DELETE_SUCCESS,
    LENDERS_BATCH_DELETE_FAILURE,
    LENDERS_FETCH_MANY_REQUEST,
    LENDERS_FETCH_MANY_SUCCESS,
    LENDERS_FETCH_MANY_FAILURE,
    LENDERS_FETCH_REQUEST,
    LENDERS_FETCH_SUCCESS,
    LENDERS_FETCH_FAILURE,
    LENDERS_SAVE_REQUEST,
    LENDERS_SAVE_SUCCESS,
    LENDERS_SAVE_FAILURE,
    EMPTY_LENDERS,
    CLEAR_ERRORS
} from "./types";

export default {
    deleteMany({ commit }, { ids = [] }) {
        commit(LENDERS_BATCH_DELETE_REQUEST);
        return window.axios
            .post("/lenders", { _method: "DELETE", ids })
            .then(response => {
                commit(LENDERS_BATCH_DELETE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(LENDERS_BATCH_DELETE_FAILURE, error);
                throw error;
            });
    },
    fetchMany({ commit }, { query = "", page = 1, extraParams = {} }) {
        commit(LENDERS_FETCH_MANY_REQUEST);
        return window.axios
            .get("/lenders", { params: { query, page, ...extraParams } })
            .then(response => {
                commit(LENDERS_FETCH_MANY_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(LENDERS_FETCH_MANY_FAILURE, error);
                throw error;
            });
    },
    fetch({ commit }, id) {
        commit(LENDERS_FETCH_REQUEST);
        return window.axios
            .get(`/lenders/${id}`)
            .then(response => {
                commit(LENDERS_FETCH_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(LENDERS_FETCH_FAILURE, error);
                throw error;
            });
    },
    save({ commit }, user) {
        const path = user.id ? `/lenders/${user.id}` : "/lenders";
        const method = user.id ? "put" : "post";

        commit(LENDERS_SAVE_REQUEST);
        return window.axios[method](path, user)
            .then(response => {
                commit(LENDERS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(LENDERS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(LENDERS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    empty({ commit }) {
        commit(EMPTY_LENDERS);
    },
    clearErrors({ commit }) {
        commit(CLEAR_ERRORS);
    }
};
