import pagination from "../../utils/pagination";
import {
    LENDERS_BATCH_DELETE_REQUEST,
    LENDERS_BATCH_DELETE_SUCCESS,
    LENDERS_BATCH_DELETE_FAILURE,
    LENDERS_FETCH_MANY_REQUEST,
    LENDERS_FETCH_MANY_SUCCESS,
    LENDERS_FETCH_MANY_FAILURE,
    LENDERS_FETCH_REQUEST,
    LENDERS_FETCH_SUCCESS,
    LENDERS_FETCH_FAILURE,
    LENDERS_SAVE_REQUEST,
    LENDERS_SAVE_SUCCESS,
    LENDERS_SAVE_FAILURE,
    EMPTY_LENDERS,
    CLEAR_ERRORS
} from "./types";

export default {
    [LENDERS_BATCH_DELETE_REQUEST](state) {
        state.deleting = true;
    },
    [LENDERS_BATCH_DELETE_SUCCESS](state) {
        state.deleting = false;
    },
    [LENDERS_BATCH_DELETE_FAILURE](state) {
        state.deleting = false;
    },
    [LENDERS_FETCH_MANY_REQUEST](state) {
        state.data = [];
        state.loading = true;
    },
    [LENDERS_FETCH_MANY_SUCCESS](state, { data }) {
        state.data = data.data;
        state.page = pagination(data);
        state.loading = false;
    },
    [LENDERS_FETCH_MANY_FAILURE](state, { validationErrors }) {
        state.validationErrors = validationErrors || {};
        state.loading = false;
    },
    [LENDERS_FETCH_REQUEST](state) {
        state.single = {};
        state.loading = true;
    },
    [LENDERS_FETCH_SUCCESS](state, { data }) {
        state.single = data;
        state.loading = false;
    },
    [LENDERS_FETCH_FAILURE](state) {
        state.loading = false;
    },
    [LENDERS_SAVE_REQUEST](state) {
        state.data = [];
        state.saving = true;
    },
    [LENDERS_SAVE_SUCCESS](state) {
        state.saving = false;
    },
    [LENDERS_SAVE_FAILURE](state, { validationErrors }) {
        state.validationErrors = validationErrors || {};
        state.saving = false;
    },
    [EMPTY_LENDERS](state) {
        state.data = [];
        state.loading = false;
        state.saving = false;
    },
    [CLEAR_ERRORS](state) {
        state.validationErrors = [];
        state.loading = false;
        state.saving = false;
    }
};
