<template>
  <b-container>
    <b-row class="mb-3">
      <b-col cols="7">
        <h1 class="font-weight-bold mb-1 text-primary">Payment #{{ $route.params.id }}</h1>
        <p class="mb-0">Order #{{ payment.order_id }} - {{ payment.location }}</p>
        <small class="text-muted">{{ payment.method }}</small>
      </b-col>
      <b-col cols="5">
        <div class="d-flex flex-column align-items-end">
          <p class="float-right mb-2">{{ moment(payment.date).format("Do MMM YYYY") }}</p>
          <small class="mb-0 text-muted">Handled By: {{ payment.user ? payment.user.name : 'Unknown' }}</small>
          <small class="mb-0 text-muted">{{ payment.user ? payment.user.email : 'Unknown' }}</small>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col cols="12">
        <p class="text-center">
          <b-spinner variant="secondary"></b-spinner>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!isLoading">
      <b-col cols="12">
        <b-card class="p-3" no-body>
          <b-row>
            <b-col cols="12">
              <h3 class="font-weight-bold mb-0">Payment Details</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <hr>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple borderless>
                <b-thead>
                  <b-tr>
                    <b-th>Payment Amount</b-th>
                    <b-th class="text-muted text-right">{{ payment.amount | currencyFormat }}</b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Payment Method</b-th>
                    <b-th class="text-muted text-right">{{ payment.method }}</b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Payment Location</b-th>
                    <b-th class="text-muted text-right">{{ payment.location }}</b-th>
                  </b-tr>
                  <b-tr>
                    <b-th>Payment Date</b-th>
                    <b-th class="text-muted text-right">{{ moment(payment.date).format("Do MMM YYYY") }}</b-th>
                  </b-tr>
                </b-thead>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <hr>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col cols="12" md="6">
                  <b-card class="p-3" no-body>
                    <b-row>
                      <b-col cols="12">
                        <div class="d-flex flex-column">
                          <h3 class="font-weight-bold mb-0">Order Details</h3>
                          <small>
                            <b-link :to="{ name: 'dash.orders.view', params: { id: payment.order_id } }"
                                    class="text-primary">View Order
                            </b-link>
                          </small>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <hr>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-table-simple borderless>
                          <b-thead>
                            <b-tr>
                              <b-th>Order ID</b-th>
                              <b-th class="text-muted text-right">#{{ payment.order_id }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th>Order Total</b-th>
                              <b-th class="text-muted text-right">{{ payment.order.total_price | currencyFormat }}
                              </b-th>
                            </b-tr>
                            <b-tr>
                              <b-th>Order Sub Total</b-th>
                              <b-th class="text-muted text-right">{{ payment.order.sub_total | currencyFormat }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th>Order VAT</b-th>
                              <b-th class="text-muted text-right">{{ payment.order.vat | currencyFormat }}</b-th>
                            </b-tr>
                          </b-thead>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col cols="12" md="6">
                  <b-card class="p-3" no-body>
                    <b-row>
                      <b-col cols="12">
                        <div class="d-flex flex-column">
                          <h3 class="font-weight-bold mb-0">Customer Details</h3>
                          <small>
                            <b-link :to="{ name: 'dash.customers.view', params: { id: payment.order.customer_id } }"
                                    class="text-primary">
                              View Customer
                            </b-link>
                          </small>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <hr>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-table-simple borderless>
                          <b-thead>
                            <b-tr>
                              <b-th>Customer ID</b-th>
                              <b-th class="text-muted text-right">#{{ payment.order.customer_id }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th>Customer Name</b-th>
                              <b-th class="text-muted text-right">{{ payment.order.customer.name }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th>Customer Email</b-th>
                              <b-th class="text-muted text-right">{{ payment.order.customer.email }}</b-th>
                            </b-tr>
                          </b-thead>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="my-3" cols="12">
        <b-card class="p-3" no-body>
          <b-row>
            <b-col cols="7">
              <h3 class="font-weight-bold mb-0">Payment Refunds</h3>
            </b-col>
            <b-col cols="5">
              <b-button
                :to="{ name: 'dash.payments.refund', params: { id: payment.id } }"
                class="float-right"
                variant="primary"
              >Create Refund
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <hr>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="payment.refunds.length">
              <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th>Refund Amount</b-th>
                    <b-th>Refund By</b-th>
                    <b-th class="text-right">Reason</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(refund, refundInd) in payment.refunds" :key="`refund${refundInd}`">
                    <b-td>{{ refund.refund_amount | currencyFormat }}</b-td>
                    <b-td>{{ refund.user.name }}</b-td>
                    <b-td class="text-right">{{ refund.reason }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col v-else>
              <b-col class="text-center" cols="12">
                <b-card class="py-3 text-center">
                  <p class="mb-4">
                    <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                  </p>
                  <p class="font-weight-normal h5 mb-0 text-dark">There are no refunds.</p>
                </b-card>
              </b-col>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation"
import {mapActions, mapGetters} from "vuex"
import momentMixin from "../../mixins/momentMixin"

export default {
  mixins: [validation, momentMixin],
  created() {
    this.fetch(this.$route.params.id)
  },
  data: () => ({
    payment: {
      id: null,
      order_id: null,
      order: {},
      user_id: null,
      user: {
        name: null,
      },
      amount: null,
      method: null,
      date: null,
      location: null,
      refunds: [],
    }
  }),
  computed: {
    ...mapGetters("payments", ["errors", "isLoading", "isSaving", "single"])
  },
  methods: {
    ...mapActions("payments", ["fetch"]),
  },
  watch: {
    single() {
      this.payment = this.single
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>
