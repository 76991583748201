export const LENDERS_BATCH_DELETE_REQUEST = "LENDERS_BATCH_DELETE_REQUEST";
export const LENDERS_BATCH_DELETE_SUCCESS = "LENDERS_BATCH_DELETE_SUCCESS";
export const LENDERS_BATCH_DELETE_FAILURE = "LENDERS_BATCH_DELETE_FAILURE";

export const LENDERS_FETCH_MANY_REQUEST = "LENDERS_FETCH_MANY_REQUEST";
export const LENDERS_FETCH_MANY_SUCCESS = "LENDERS_FETCH_MANY_SUCCESS";
export const LENDERS_FETCH_MANY_FAILURE = "LENDERS_FETCH_MANY_FAILURE";

export const LENDERS_FETCH_REQUEST = "LENDERS_FETCH_REQUEST";
export const LENDERS_FETCH_SUCCESS = "LENDERS_FETCH_SUCCESS";
export const LENDERS_FETCH_FAILURE = "LENDERS_FETCH_FAILURE";

export const LENDERS_SAVE_REQUEST = "LENDERS_SAVE_REQUEST";
export const LENDERS_SAVE_SUCCESS = "LENDERS_SAVE_SUCCESS";
export const LENDERS_SAVE_FAILURE = "LENDERS_SAVE_FAILURE";

export const EMPTY_LENDERS = "EMPTY_LENDERS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
